import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import QrLayout from "layouts/QR";
import { history } from "utils";
import theme from "theme/theme";

const Main = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                {/* <CssBaseline /> */}
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Router history={history}>
                    <Switch>
                        <Route path="/" render={(props) => <QrLayout {...props} />} />
                        <Redirect from="/" to="/nickyjacob" />
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    );
};

export default Main;
