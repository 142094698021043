import QrHome from "views/qr";


var qrRoutes = [
    {
        path: ":username",
        name: "Qr",
        component: QrHome,
        layout: "/",
        visibility: true,
    },
];



export { qrRoutes };
