import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { history } from "utils";
import { unAuthorizedPost } from "services";
import { CREATE_SECURO_PAYMENT_REQUEST } from "graphql";
import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import { Formik } from "formik";
import * as yup from 'yup';
import { securoFiatCurrencies } from "datas/securoFiatCurrencies";

const QrHome = () => {
    const { username } = useParams();
    const [paymentMethodes, setPaymentMethodes] = useState(securoFiatCurrencies[0].paymentOptions);
    const [selectedPmethode, setSelectedPmethode] = useState(securoFiatCurrencies[0].paymentOptions[0].id);
    const [loading, setLoading] = useState(false);
    const [fiat, setFiat] = useState("USD");

    useEffect(() => {
        if (username) {
            document.body.style.backgroundColor = "#222831";
        }
    }, []);

    const handleSubmit = async (requestData) => {
        try {
            setLoading(true);

            let apiResponse = await unAuthorizedPost("", {
                query: CREATE_SECURO_PAYMENT_REQUEST,
                variables: requestData,
            });

            if (apiResponse.data.customStatus) {
                let {
                    create_securo_pmt_request: { data },
                } = apiResponse.data.data;

                window.open(data?.url)
                setLoading(false)
                return;
            } else {
                toast.error("Failed to create payment");
                setLoading(false)
                // return history.push("/nickyjacob");
            }
        } catch (err) {
            toast.error("Unable to create payment");
            setLoading(false)
            // return history.push("/nickyjacob");
        }
    };

    const handleFiatChange = (event) => {
        let filtered = securoFiatCurrencies.filter((e) => event.target.value === e.symbol);
        setFiat(event.target.value);
        setPaymentMethodes(filtered[0].paymentOptions);
        setSelectedPmethode(
            filtered[0].paymentOptions.filter((j) => j.id === "credit_debit_card").length
                ? "credit_debit_card"
                : filtered[0].paymentOptions[0].id
        );
        // setFiatAmount(filtered[0].paymentOptions[0].minAmount)
        // setFiatAmount(30);
    };

    const handlePmethodeChange = async (event) => {
        await setSelectedPmethode(event.target.value);
        let filtered = await paymentMethodes.filter((e) => selectedPmethode === e.id);
        // setFiatAmount(filtered[0].minAmount);
    };

    const handleFormSubmit = (values) => {
        let filtered = paymentMethodes.filter((e) => selectedPmethode === e.id);
        if (values.amount < filtered[0].minAmount) {
            return toast.error(`Minimum amount is ${filtered[0].minAmount}`);
        }

        values.merchantUsername = username
        values.data = {
            network: "solana",
            fiatCurrency: fiat,
            fiatAmount: parseFloat(values.amount),
            cryptoCurrency: "USDC",
            paymentMethod: selectedPmethode,
            trxType: "Buy",
        }
        handleSubmit(values);
    };
    const initialValues = {
        email: "",
        amount: "",
    };
    const checkoutSchema = yup.object().shape({
        email: yup.string().email("Invalid email!").required("Required"),
        amount: yup.number().min(1).required("Required"),

    })

    return (
        <>
            <Container>
                <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item lg={5} md={6} sm={8} xs={12}>
                            <Box m={2} p={3} bgcolor={"white"} borderRadius={1}>
                                <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Stack spacing={2}>
                                                <Stack>
                                                    <InputLabel>Email</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        size="small"
                                                        type="text"
                                                        placeholder="Enter email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        name="email"
                                                        error={!!touched.email && !!errors.email}
                                                    />
                                                    {Boolean(touched.email && errors.email) ? (
                                                        <div
                                                            style={{
                                                                display: "block",
                                                                marginLeft: "10px",
                                                                color: "red",
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            {errors.email}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Stack>
                                                <Stack>
                                                    <InputLabel>Amount</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        size="small"
                                                        type="number"
                                                        placeholder="Enter amount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.amount}
                                                        name="amount"
                                                        error={!!touched.amount && !!errors.amount}
                                                    />
                                                    {Boolean(touched.amount && errors.amount) ? (
                                                        <div
                                                            style={{
                                                                display: "block",
                                                                marginLeft: "10px",
                                                                color: "red",
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            {errors.amount}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Stack>
                                                <Stack>
                                                    <InputLabel>Select Fiat Currency</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select fullWidth size="small" value={fiat} onChange={handleFiatChange}>
                                                            {securoFiatCurrencies.map((e, i) => (
                                                                <MenuItem value={e.symbol} key={i}>
                                                                    {e.name} ({e.symbol})
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                                <Stack>
                                                    <InputLabel>Select Payment Method</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select fullWidth size="small" value={selectedPmethode} onChange={handlePmethodeChange}>
                                                            {paymentMethodes.map((e, i) => (
                                                                <MenuItem value={e.id} key={i}>
                                                                    {e.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                                <Button 
                                                    fullWidth 
                                                    variant="contained" 
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    {loading ? <CircularProgress size={20} color={"secondary"}/> :  "Continue"}
                                                </Button>
                                            </Stack>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default QrHome;
