import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { qrRoutes } from "routes.js";
import "./admin.css";

const QR = () => {
    const location = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return <Redirect to={"/nickyjacob"} key={key} />;
            }
        });
    };

    return (
        <>
            <div>
                <Switch>
                    {getRoutes(qrRoutes)}
                    <Redirect from="*" to="/nickyjacob" />
                </Switch>
            </div>
        </>
    );
};

export default QR;
