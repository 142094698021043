export const securoFiatCurrencies = [
    {
        symbol: "USD",
        supportingCountries: ["US"],
        logoSymbol: "US",
        isAllowed: true,
        name: "US Dollar",
        roundOff: 2,
        paymentOptions: [
            {
                name: "Card Payment",
                id: "credit_debit_card",
                processingTime: "1-3 minutes",
                dailyLimit: 5000,
                limitCurrency: "USD",
                maxAmount: 1500,
                isActive: true,
                minAmount: 30,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Apple Pay",
                id: "apple_pay",
                processingTime: "1-4 minutes",
                dailyLimit: 5000,
                limitCurrency: "USD",
                maxAmount: 1500,
                isActive: true,
                minAmount: 30,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Google Pay",
                id: "google_pay",
                processingTime: "1-4 minutes",
                dailyLimit: 14000,
                limitCurrency: "USD",
                maxAmount: 1500,
                isActive: true,
                minAmount: 30,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
        ],
        defaultAmount: 1000,
    },
    {
        symbol: "GBP",
        supportingCountries: ["GB"],
        logoSymbol: "GB",
        isAllowed: true,
        name: "British pound",
        roundOff: 2,
        paymentOptions: [
            {
                name: "Bank Transfer",
                id: "gbp_bank_transfer",
                processingTime: "1-3 minutes",
                dailyLimit: 11310,
                limitCurrency: "USD",
                maxAmount: 11310,
                isActive: true,
                minAmount: 16,
                isPayOutAllowed: true,
                minAmountForPayOut: 50,
                maxAmountForPayOut: 5000,
            },
            {
                name: "Open Banking",
                id: "pm_open_banking",
                processingTime: "1-3 minutes",
                dailyLimit: 11310,
                limitCurrency: "USD",
                maxAmount: 1212,
                isActive: true,
                minAmount: 24,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Card Payment",
                id: "credit_debit_card",
                processingTime: "1-3 minutes",
                dailyLimit: 11310,
                limitCurrency: "USD",
                maxAmount: 1212,
                isActive: true,
                minAmount: 24,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Apple Pay",
                id: "apple_pay",
                processingTime: "1-4 minutes",
                dailyLimit: 11310,
                limitCurrency: "USD",
                maxAmount: 1212,
                isActive: true,
                minAmount: 24,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Google Pay",
                id: "google_pay",
                processingTime: "1-4 minutes",
                dailyLimit: 11310,
                limitCurrency: "USD",
                maxAmount: 1212,
                isActive: true,
                minAmount: 24,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
        ],
        defaultAmount: 1000,
    },
    // {
    //     symbol: "INR",
    //     supportingCountries: ["IN", "BT"],
    //     logoSymbol: "IN",
    //     isAllowed: false,
    //     name: "Indian rupee",
    //     roundOff: 2,
    //     paymentOptions: [
    //         {
    //             name: "UPI",
    //             id: "upi",
    //             processingTime: "2-5 minutes",
    //             dailyLimit: 100000,
    //             maxAmount: 100000,
    //             isActive: false,
    //             minAmount: 1500,
    //             isPayOutAllowed: false,
    //             minAmountForPayOut: null,
    //             maxAmountForPayOut: null,
    //         },
    //         {
    //             name: "Mobikwik",
    //             id: "mobikwik_wallet",
    //             processingTime: "10-15 minutes",
    //             dailyLimit: 1045581,
    //             maxAmount: 999999,
    //             isActive: false,
    //             minAmount: 1500,
    //             isPayOutAllowed: false,
    //             minAmountForPayOut: null,
    //             maxAmountForPayOut: null,
    //         },
    //     ],
    //     defaultAmount: 5000,
    // },
    {
        symbol: "EUR",
        supportingCountries: [
            "AT",
            "BE",
            "DK",
            "EE",
            "FI",
            "CY",
            "NOK",
            "FR",
            "CH",
            "DE",
            "HU",
            "PL",
            "GR",
            "IE",
            "IT",
            "LV",
            "LT",
            "LU",
            "MT",
            "NL",
            "PT",
            "SK",
            "SI",
            "ES",
            "SE",
            "AD",
            "GF",
            "GP",
            "MQ",
            "YT",
            "ME",
            "RE",
            "BL",
            "MF",
            "PM",
            "AX",
            "VA",
        ],
        logoSymbol: "EU",
        isAllowed: true,
        name: "Euro",
        roundOff: 2,
        paymentOptions: [
            {
                name: "Bank Transfer",
                id: "sepa_bank_transfer",
                processingTime: "1 to 2 days",
                dailyLimit: 13146,
                limitCurrency: "USD",
                maxAmount: 13146,
                isActive: true,
                minAmount: 19,
                isPayOutAllowed: true,
                minAmountForPayOut: 50,
                maxAmountForPayOut: 5000,
            },
            {
                name: "Card Payment",
                id: "credit_debit_card",
                processingTime: "1-3 minutes",
                dailyLimit: 13146,
                limitCurrency: "USD",
                maxAmount: 1409,
                isActive: true,
                minAmount: 28,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Apple Pay",
                id: "apple_pay",
                processingTime: "1-4 minutes",
                dailyLimit: 13146,
                limitCurrency: "USD",
                maxAmount: 1409,
                isActive: true,
                minAmount: 28,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "Google Pay",
                id: "google_pay",
                processingTime: "1-4 minutes",
                dailyLimit: 13146,
                limitCurrency: "USD",
                maxAmount: 1409,
                isActive: true,
                minAmount: 28,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
        ],
        defaultAmount: 1000,
    },
    {
        symbol: "MXN",
        supportingCountries: ["MX"],
        logoSymbol: "MX",
        isAllowed: true,
        name: "Mexican peso",
        roundOff: 2,
        paymentOptions: [
            {
                name: "Card Payment",
                id: "credit_debit_card",
                processingTime: "1-3 minutes",
                dailyLimit: 273427,
                limitCurrency: "USD",
                maxAmount: 29296,
                isActive: true,
                minAmount: 600,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
            {
                name: "AstroPay",
                id: "pm_astropay",
                processingTime: "1-3 minutes",
                dailyLimit: 273427,
                limitCurrency: "USD",
                maxAmount: 29296,
                isActive: true,
                minAmount: 600,
                isPayOutAllowed: false,
                minAmountForPayOut: null,
                maxAmountForPayOut: null,
            },
        ],
        defaultAmount: 2500,
    },
];